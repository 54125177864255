import { Injectable } from '@angular/core';
import {
  ContributorService,
  CreateUserAccountValues,
  MyUserAccountService,
} from '@swagger/humanresources';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppAccountService {

  constructor(
    private myUserAcc: MyUserAccountService,
    private employee: ContributorService
  ) {}

  getExistingUserAccounts(id: string) {
    //return this.userAccounts.userAccountQueryUsers({body: {}}).pipe(map((res) => res.result));
    return this.employee
      .contributorGetAssignedUserAccounts({
        contributorUId: id,
        eagerLoading: 2,
      })
      .pipe(
        map((res) => res.result.filter((acc) => acc?.user?.data?.status !== 4))
      );
  }

  get myUserAccount() {
    return this.myUserAcc.myUserAccountGetMe().pipe(map((res) => res.result));
  }

  /**
   * Creates a new user account and assigns it to the employee
   */
  createUserAccountAndAssignToEmployee(
    account: CreateUserAccountValues,
    id: string
  ) {
    return this.employee.contributorCreateAndAssignUserAccount({
      contributorUId: id,
      body: { ...account },
    });
  }

  getMeOrganizationalUnits() {
    return this.myUserAcc
      .myUserAccountGetMe()
      .pipe(map((res) => res.result?.ou));
  }
}
