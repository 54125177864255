<div
  *ngIf="myAccount$ | async; let account"
  class="w-full flex flex-col px-12 py-6 gap-4"
>
  <div class="flex flex-col gap-1">
    <p>Benutzername</p>
    <p class="hrp-text-body-emph">{{ account.name }}</p>
  </div>
  <div class="flex flex-col gap-1" *ngFor="let role of account.roles">
    <p>Rolle</p>
    <p class="hrp-text-body-emph">{{ role.displayLabel }}</p>
  </div>
  <div class="flex flex-col gap-1">
    <p>Organisationseinheit</p>
    <p *ngFor="let ou of account?.ou" class="hrp-text-body-emph">{{ ou?.displayLabel }}</p>
  </div>
  <div class="flex flex-col gap-1">
    <p>Access Token</p>

    @if ($accessTokenExpiresAt()) {
    <p class="hrp-text-body-emph">
      Der Access Token
      <!-- wurde am
      {{ $accessTokenCreatedAt() | date : "medium" }} Uhr erstellt und  -->
      ist bis
      zum {{ $accessTokenExpiresAt() | date : "medium" }} Uhr gültig.
    </p>
    }
    <p>
      Access Token läuft {{ $accessTokenExpiresIn() }} ab.
      <button
        type="button"
        (click)="refreshAccessToken()"
        class="inline-block tertiary small"
      >
        jetzt erneuern
      </button>
    </p>
  </div>
</div>
