import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageAccountComponent } from './account.component';
import { PageAccountContentComponent } from './content/account-content.component';
import { PageAccountMyAccountComponent } from './content/account-my-account.component';

const routes: Routes = [
  { path: '', component: PageAccountComponent, children: [
    { path: 'setting', component: PageAccountContentComponent },
    { path: 'myaccount', component: PageAccountMyAccountComponent }
  ], }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PageAccountRoutingModule {}
