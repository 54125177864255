<par-progress-spinner *ngIf="loading$ | async; else accountTemplate" size="54" mode="indeterminate" class="justify-self-center"></par-progress-spinner>

<ng-template #accountTemplate>
  <ng-container *transloco="let t">
    <ng-container *ngIf="name$ | async; let name">
      <ng-container *ngIf="name === 'general'">
        <div class="w-full flex flex-col px-12 py-6 gap-4">
          <h1 class="text-lg font-bold">{{t('account-general')}}</h1>
        <!-- <div class="flex flex-row items-center mt-2">
          <label class="w-24">{{t('account-theme')}}</label>
          <par-select class="w-96" [value]="(theme$ | async) || ''" (valueChange)="themeChanged($event)" [displayNameFn]="getDisplayTheme.bind(this)">
            <par-select-option value="modern"><ng-container single>{{t('account-theme-modern')}}</ng-container></par-select-option>
            <par-select-option value="isa"><ng-container single>{{t('account-theme-isa')}}</ng-container></par-select-option>
            <par-select-option value="warm"><ng-container single>{{t('account-theme-warm')}}</ng-container></par-select-option>
            <par-select-option value="light"><ng-container single>{{t('account-theme-light')}}</ng-container></par-select-option>
            <par-select-option value="dark"><ng-container single>{{t('account-theme-dark')}}</ng-container></par-select-option>
          </par-select>
        </div> -->
        <div class="flex flex-row items-center mt-2">
          <label class="w-24">{{t('account-language')}}</label>
          <par-select class="w-96" [value]="(language$ | async) || ''" (valueChange)="languageChanged($event)" [displayNameFn]="getDisplayLanguage.bind(this)">
            <par-select-option value="de"><ng-container single>{{t('account-german')}}</ng-container></par-select-option>
            <par-select-option value="en"><ng-container single>{{t('account-english')}}</ng-container></par-select-option>
          </par-select>
        </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

