import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageAccountComponent } from './account.component';
import { PageAccountRoutingModule } from './account-routing.module';
import { PageAccountSidebarComponent } from './sidebar/account-sidebar.component';
import { PageAccountContentComponent } from './content/account-content.component';

@NgModule({
    imports: [PageAccountRoutingModule, CommonModule, PageAccountSidebarComponent, PageAccountContentComponent],
    exports: [PageAccountComponent],
    declarations: [PageAccountComponent]
})
export class PageAccountModule { }
