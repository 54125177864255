import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthService } from '@core/auth';
import { AppAccountService } from '@features/account';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { SelectModule } from '@paragondata/ngx-ui/select';
import moment from 'moment';
import { interval } from 'rxjs';
import { AppStore } from 'src/app/store/app/app.store';

@Component({
  selector: 'page-account-my-account',
  templateUrl: 'account-my-account.component.html',
  styleUrls: ['account-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    ParProgressModule,
    SelectModule,
    TranslocoModule,
    DatePipe,
  ],
  standalone: true,
})
export class PageAccountMyAccountComponent implements OnInit {
  readonly auth = inject(AuthService);

  myAccount$ = this.userAccount.myUserAccount;

  private $interval = toSignal(interval(1000));

  $accessTokenClaims = computed(() => {
    this.$interval();
    return this.auth.getClaims();
  });

  $accessTokenExpiresAt = computed(() => {
    const claims = this.$accessTokenClaims();
    const date = moment.unix(claims.exp)?.toDate();
    return date instanceof Date && !isNaN(date.getTime()) ? date : undefined;
  });

  $accessTokenExpiresIn = computed(() => {
    const accessTokenExpiresAt = this.$accessTokenExpiresAt();
    return moment(accessTokenExpiresAt).fromNow();
  });

  $accessTokenCreatedAt = computed(() => {
    const claims = this.$accessTokenClaims();
    const date = moment.unix(claims.nbf)?.toDate();
    //hier kommt immer Invalid Date raus, deshalb wurde das im template auskommentiert
    return date instanceof Date && !isNaN(date.getTime()) ? date : undefined;
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private app: AppStore,
    private transloco: TranslocoService,
    private userAccount: AppAccountService
  ) {}

  ngOnInit(): void {}

  async refreshAccessToken() {
    if (await this.auth.refresh()) {
      console.log('Token refreshed');
    } else {
      console.log('Token refresh failed');
    }
  }
}
