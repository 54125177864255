import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@core/auth';

@Component({
    selector: 'page-account',
    templateUrl: 'account.component.html',
    styleUrls: ['account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageAccountComponent {
    constructor(private authService: AuthService) { }

    logout(){
      this.authService.logout();
    }
}