import { ArrayDataSource } from '@angular/cdk/collections';
import { CdkTreeModule, FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { ComponentStore } from '@ngrx/component-store';
import { NavbarItemBasic } from '@core/defs';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface PageAccountSidebarState {
  loading: boolean;
  error?: string;
  active?: string;
}

@Component({
  selector: 'page-account-sidebar',
  templateUrl: 'account-sidebar.component.html',
  styleUrls: ['account-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, CdkTreeModule, ParProgressModule, TranslocoModule],
  standalone: true
})
export class PageAccountSidebarComponent
  extends ComponentStore<PageAccountSidebarState>
  implements OnInit, OnDestroy
{
  private _onDestroy$ = new Subject();
  loading$ = this.select(state => state.loading);
  error$ = this.select(state => state.error);
  active$ = this.select(state => state.active);

  data = [
    {title: 'general', active: true, routerLink: 'setting', queryParams: {name: 'general'}},
    {title: 'account', active: false, routerLink: 'myaccount', queryParams: {name: 'account'}},

  ] as NavbarItemBasic[];



  dataSource = new ArrayDataSource(this.data);
  treeControl = new FlatTreeControl<NavbarItemBasic>(
    node => node.level || 1,
    node => node.expandable || false,
  );
  hasChild = (_: number, node: any) =>
    !!node.keyValueMetaChildren && node.keyValueMetaChildren.length > 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super({ loading: true });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams?.pipe(takeUntil(this._onDestroy$)).subscribe((params) => {
      this.patchState({ active: params.name });
    });
    this.patchState({loading: false})
    this.router.navigate(['/account/setting'], {queryParams: {name: 'general'}});
  }

  ngOnDestroy(): void {
    this._onDestroy$.next({});
    this._onDestroy$.complete();
  }
}
