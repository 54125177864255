import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ComponentStore } from '@ngrx/component-store';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { SelectModule } from '@paragondata/ngx-ui/select';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStore } from 'src/app/store/app/app.store';

interface PageAccountContentState {
  loading: boolean;
  error?: string;
  name?: string;
}

@Component({
  selector: 'page-account-content',
  templateUrl: 'account-content.component.html',
  styleUrls: ['account-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, ParProgressModule, SelectModule, TranslocoModule],
  standalone: true
})
export class PageAccountContentComponent
  extends ComponentStore<PageAccountContentState>
  implements OnInit, OnDestroy
{
  private _onDestroy$ = new Subject();
  private langMap = {'en': 'account-english', 'de': 'account-german'};
  private themeMap = {'modern': 'account-theme-modern', 'isa': 'account-theme-isa', 'light': 'account-theme-light', 'dark': 'account-theme-dark', 'warm': 'account-theme-warm'}
  
  loading$ = this.select(state => state.loading);
  error$ = this.select(state => state.error);
  name$ = this.select(state => state.name);

  language$ = this.app.language$;
  theme$ = this.app.theme$;

  constructor(
    private activatedRoute: ActivatedRoute,
    private app: AppStore,
    private transloco: TranslocoService
  ) {
    super({ loading: true });
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams?.pipe(takeUntil(this._onDestroy$)).subscribe((params) => {
      this.patchState({name: params.name});
    });
    setTimeout(() => this.patchState({loading: false}), 150);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next({});
    this._onDestroy$.complete();
  }

  languageChanged(language: string) {
    this.app.setLanguage(language);
  }

  async getDisplayLanguage(key: string) {
    const i18nKey = (this.langMap as any)[key];
    return await firstValueFrom(this.transloco.selectTranslate(i18nKey));
  }
  
  themeChanged(theme: string) {
    this.app.setTheme(theme.toLocaleLowerCase());
  }

  async getDisplayTheme(key: string) {
    const themeKey = (this.themeMap as any)[key];
    return await firstValueFrom(this.transloco.selectTranslate(themeKey));
  }
}
