<par-progress-spinner *ngIf="loading$ | async; else sidebarTemplate" size="54" mode="indeterminate"></par-progress-spinner>

<ng-template #sidebarTemplate>
  <div class="sidebar pt-6" *transloco="let t">
      <cdk-tree class="treeview" [dataSource]="dataSource" [treeControl]="treeControl">
        <cdk-tree-node *cdkTreeNodeDef="let node" class="tree-node">
          <a
            class="tree-link"
            [class.active]="(active$ | async) === node?.title"
            [routerLink]="node.routerLink"
            [queryParams]="node.queryParams"
          >
            {{ t('account-'+node?.title) }}
          </a>
        </cdk-tree-node>
        <cdk-tree-node
          class="tree-node"
          *cdkTreeNodeDef="let node; when: hasChild"
        >
          <a
            class="tree-link"
            [class.active]="(active$ | async) === node?.title"
            [routerLink]="node.routerLink"
            [queryParams]="node.queryParams"
          >
            {{ t('account-'+node?.title) }}
          </a>
          <ng-container cdkTreeNodeOutlet></ng-container>
        </cdk-tree-node>
      </cdk-tree>

  </div>
</ng-template>
